import { Layout as AntLayout, Divider } from 'antd';

const containerStyle = isLoggedin => ({
  paddingTop: 48,
  paddingBottom: 48,
  margin: `0 auto`,
  maxWidth: isLoggedin ? 1200 : 560,
});

export default function Layout({ userName, onLogout, children }) {
  return (
    <AntLayout>
      <header className="navbar">
        <div className="navbar-title">ATS Web 運用管理</div>
        {userName && (
          <div className="navbar-items">
            <span>{userName}</span>
            <Divider type="vertical" />
            <span className="navbar-link" onClick={onLogout}>
              ログアウト
            </span>
          </div>
        )}
      </header>
      <AntLayout.Content>
        <div style={containerStyle(!!userName)}>{children}</div>
      </AntLayout.Content>
    </AntLayout>
  );
}
