import { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  Empty,
  Table,
  Row,
  Col,
  message,
  Typography,
} from 'antd';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { searchUsers } from '../api';

const { Text } = Typography;
const formItemStyle = { marginBottom: 0 };
const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
const renderDate = val => moment(val).format(DATETIME_FORMAT);

export default function SearchStaff() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [params, setParams] = useState({ username: '', page: 1 });
  const [pages, setPages] = useState({ current: 1, total: 0 });
  const [searching, setSearching] = useState(false);

  const search = useCallback(async () => {
    setSearching(true);

    const response = await searchUsers(params).catch(err => err.response);
    console.log(response);

    if (response.status === 200) {
      const { data, current_page: current, total } = response.data;
      setUsers(data);
      setPages({ current, total });
    } else {
      message.error('エラーが発生しました。');
    }

    setSearching(false);
  }, [params]);

  const submit = async value => {
    setParams({ ...value, page: 1 });
  };

  const paginate = async page => {
    setParams({ ...params, page });
  };

  useEffect(() => {
    search();
  }, [search]);

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <Searcher form={form} onSearch={submit} />
      </div>

      {users.length === 0 && !searching ? (
        <Card>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
      ) : (
        <Results
          items={users}
          loading={searching}
          pages={pages}
          paginate={paginate}
        />
      )}
    </>
  );
}

function Searcher({ form, onSearch }) {
  return (
    <Card>
      <Form form={form} onFinish={onSearch} labelAlign="left" colon={false}>
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item label="ユーザーID" name="username" style={formItemStyle}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={4}>
            <div style={{ textAlign: 'right' }}>
              <Form.Item shouldUpdate style={formItemStyle}>
                {() => (
                  <Button
                    icon={<SearchOutlined />}
                    htmlType="submit"
                    disabled={!form.getFieldValue('username')}
                  >
                    検索
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

function Results({ items, loading, pages, paginate }) {
  return (
    <Table
      dataSource={items}
      bordered
      size="small"
      loading={loading}
      rowKey={record => record.id}
      pagination={{
        pageSize: 20,
        current: pages.current,
        total: pages.total,
        showSizeChanger: false,
        onChange: paginate,
      }}
    >
      <Table.Column
        title="連絡先ID"
        dataIndex="external_app_id"
        key="external_app_id"
      />
      <Table.Column
        title="Matchingood接続"
        dataIndex="app_connected"
        key="app_connected"
        render={val => (val ? '✅' : '❌')}
      />
      <Table.Column title="ユーザーID" dataIndex="username" key="username" />
      <Table.Column
        title="データインポート日"
        dataIndex="created_at"
        key="created_at"
        render={renderDate}
      />
      <Table.Column
        title="マイページ仮登録"
        dataIndex="provisional_registered_at"
        key="provisional_registered_at"
        render={val =>
          val ? renderDate(val) : <Text type="danger">未登録</Text>
        }
      />
      <Table.Column
        title="マイページ登録"
        dataIndex="registered_at"
        key="registered_at"
        render={val =>
          val ? renderDate(val) : <Text type="danger">未登録</Text>
        }
      />
    </Table>
  );
}
