// ログイン
export async function login(data) {
  await window.axios.get('/sanctum/csrf-cookie');

  return window.axios.post('/login', data);
}

// ログアウト
export function logout() {
  return window.axios.post('/logout');
}

// ログインユーザー取得
export function getCurrentUser() {
  return window.axios.get('/user');
}

// ログインユーザー取得
export function searchUsers(params = {}) {
  return window.axios.get('/users', { params });
}

// スタッフ検索
export function searchStaff(params) {
  return window.axios.get('/staff', { params });
}

// マイページ仮アカウント登録
export function provisionalRegister(data) {
  return window.axios.post('/provisional-register', data);
}

// マイページアカウント削除
export function deleteUser(id) {
  return window.axios.delete(`/staff/${id}`);
}

// 設定ステータス確認
export function statusPorse() {
  return window.axios.get('/settings/job-sync');
}

// 求人同期バッチ 一時停止
export function enablePorse() {
  return window.axios.put('/settings/job-sync');
}

// 求人同期バッチ 一時停止解除
export function disablePorse() {
  return window.axios.delete('/settings/job-sync');
}