import { Tabs } from 'antd';
import Page from '../components/Page';
import SearchStaff from './SearchStaff';
import SearchAccount from './SearchAccount';
import Setup from './Setup';

const { TabPane } = Tabs;

export default function Search() {
  return (
    <Page>
      <Tabs type="card">
        <TabPane tab="Matchingood スタッフ検索" key="search-staff">
          <SearchStaff />
        </TabPane>
        <TabPane tab="テクパスユーザーマスタ検索" key="search-account">
          <SearchAccount />
        </TabPane>
        <TabPane tab="設定" key="setup">
          <Setup />
        </TabPane>
      </Tabs>
    </Page>
  );
}
