import { Skeleton } from 'antd';
import './App.less';
import Layout from './components/Layout';
import Page from './components/Page';
import Search from './views/Search';
import Login from './views/Login';
import useCurrentUser from './hooks/current-user';
import { logout } from './api';

function Loading() {
  return (
    <Page>
      <Skeleton active />
    </Page>
  );
}

function App() {
  const { user, setUser, userFetching } = useCurrentUser();

  const handleSuccess = user => setUser(user);

  const render = () => {
    if (userFetching) return <Loading />;
    if (user) return <Search />;
    return <Login onSuccess={handleSuccess} />;
  };

  const handleLogout = async () => {
    await logout();
    setUser(null);
  };

  return (
    <Layout userName={user ? user.username : null} onLogout={handleLogout}>
      {render()}
    </Layout>
  );
}

export default App;
