import { Card } from 'antd';

export const shadow = { boxShadow: `0 6px 10px rgba(11, 66, 120, .08)` };

export default function Page({ children, ...rest }) {
  return (
    <Card bordered={false} className="Page" {...rest}>
      {children}
    </Card>
  );
}
