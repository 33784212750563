import { useMemo, useState } from 'react';
import {
  Button,
  Card,
  Form,
  Input,
  DatePicker,
  Empty,
  Table,
  Row,
  Col,
  Space,
  Modal,
  message,
  Typography,
  Popconfirm,
} from 'antd';
import moment from 'moment';
import {
  SearchOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import {
  searchStaff,
  provisionalRegister,
  deleteUser as deleteUserApi,
} from '../api';
import download from '../download';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const formItemStyle = { marginBottom: 0 };

const linkButtonStyle = {
  padding: 0,
  backgroundColor: `transparent`,
  border: 0,
  outline: 0,
  font: `inherit`,
  color: `inherit`,
  cursor: 'pointer',
};

const DATETIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default function SearchStaff() {
  const [form] = Form.useForm();
  const [staffs, setStaffs] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searching, setSearching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [registering, setRegistering] = useState(false);

  const selectedStaffs = useMemo(
    () => staffs.filter(staff => selectedRowKeys.includes(staff.matchingoodId)),
    [staffs, selectedRowKeys]
  );

  // 検索
  const search = async value => {
    setSearching(true);

    const { matchingoodId, registeredAt } = value;
    const params = { candidateId: matchingoodId };

    if (registeredAt) {
      const [from, to] = registeredAt.map(item => item.format('YYYY/MM/DD'));
      params.registrationDateFrom = from;
      params.registrationDateTo = to;
    }

    const response = await searchStaff(params).catch(err => err.response);

    if (response.status === 200) {
      setStaffs(response.data.filter(item => item.name !== '非会員応募'));
    } else {
      message.error('エラーが発生しました。');
    }

    setSearching(false);
  };

  // 仮アカウント発行
  const handleOk = async () => {
    setRegistering(true);

    const response = await provisionalRegister({
      users: selectedStaffs.map(staff => ({
        external_app_id: staff.matchingoodId,
        email: staff.email,
      })),
    }).catch(err => err.response);

    if (response.status === 200) {
      setModalOpen(false);
      setSelectedRowKeys([]);
      message.success('仮アカウントを発行しました。');
      form.submit();
    } else {
      message.error('エラーが発生しました。');
    }

    setRegistering(false);
  };

  // ユーザー削除
  const deleteUser = async id => {
    const hide = message.loading('ユーザーを削除しています。');

    const response = await deleteUserApi(id).catch(e => e.response);

    hide();

    if (response.status === 200) {
      setModalOpen(false);
      message.success('ユーザーを削除しました。');
      form.submit();
      download(
        JSON.stringify(response.data, null, '  '),
        `${response.data.external_app_id}_${response.data.username}.json`,
        'text/json'
      );
    } else {
      message.error('エラーが発生しました。');
    }
  };

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <Searcher form={form} onSearch={search} />
      </div>
      {staffs.length === 0 && !searching ? (
        <Card>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Card>
      ) : (
        <Space direction="vertical" size="middle" style={{ width: `100%` }}>
          <Space size="middle">
            <span>チェックしたユーザーに</span>
            <Button
              icon={<UserAddOutlined />}
              onClick={() => setModalOpen(true)}
              disabled={selectedRowKeys.length === 0}
            >
              仮アカウントを発行する
            </Button>
          </Space>
          <Modal
            title="仮アカウント発行"
            visible={modalOpen}
            okButtonProps={{ loading: registering }}
            onOk={handleOk}
            onCancel={() => setModalOpen(false)}
          >
            <p>以下のユーザーに仮アカウントを発行します。</p>
            <ul style={{ marginBottom: 0 }}>
              {selectedStaffs.map(staff => (
                <li key={staff.matchingoodId}>
                  {staff.name}（{staff.matchingoodId}）
                </li>
              ))}
            </ul>
          </Modal>
          <Staffs
            items={staffs}
            selectedRowKeys={selectedRowKeys}
            onRowSelect={keys => setSelectedRowKeys(keys)}
            onDelete={id => deleteUser(id)}
            loading={searching}
          />
        </Space>
      )}
    </>
  );
}

function Searcher({ form, onSearch }) {
  const [dates, setDates] = useState([]);

  const disabledDate = current => {
    const afterTomorrow = current && current > moment().endOf('day');

    if (!dates || dates.length === 0) return afterTomorrow;

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;

    return afterTomorrow || tooEarly || tooLate;
  };

  return (
    <Card>
      <Form form={form} onFinish={onSearch} labelAlign="left" colon={false}>
        <Row gutter={24}>
          <Col span={7}>
            <Form.Item label="連絡先ID" name="matchingoodId" style={formItemStyle}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              label="Matchingood登録日"
              name="registeredAt"
              style={formItemStyle}
            >
              <RangePicker
                style={{ width: `100%` }}
                format="YYYY/MM/DD"
                defaultPickerValue={[
                  moment().add(-1, 'month'),
                  moment().add(-1, 'month'),
                ]}
                disabledDate={disabledDate}
                onCalendarChange={val => setDates(val)}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <div style={{ textAlign: 'right' }}>
              <Form.Item shouldUpdate style={formItemStyle}>
                {() => (
                  <Button
                    icon={<SearchOutlined />}
                    htmlType="submit"
                    disabled={
                      !form.getFieldValue('matchingoodId') &&
                      !form.getFieldValue('registeredAt')
                    }
                  >
                    検索
                  </Button>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

function Staffs({ items, selectedRowKeys, onRowSelect, onDelete, loading }) {
  return (
    <Table
      dataSource={items}
      bordered
      size="small"
      loading={loading}
      rowKey={record => record.matchingoodId}
      rowSelection={{
        selectedRowKeys,
        onChange: keys => onRowSelect(keys),
      }}
    >
      <Table.Column title="連絡先ID" dataIndex="matchingoodId" key="matchingoodId" />
      <Table.Column title="氏名" dataIndex="name" key="name" />
      <Table.Column title="メールアドレス" dataIndex="email" key="email" />
      <Table.Column
        title="ユーザーID"
        dataIndex="username"
        key="username"
        render={val => val || <Text type="danger">未登録</Text>}
      />
      <Table.Column
        title="Matchingood登録"
        dataIndex="registeredAt"
        key="registeredAt"
        render={val =>
          moment(val, DATETIME_FORMAT).add(9, 'hours').format(DATETIME_FORMAT)
        }
      />
      <Table.Column
        title="マイページ仮登録"
        dataIndex="mypageProvisionalRegisteredAt"
        key="mypageProvisionalRegisteredAt"
        render={val => val || <Text type="danger">未登録</Text>}
      />
      <Table.Column
        title="マイページ登録"
        dataIndex="mypageRegisteredAt"
        key="mypageRegisteredAt"
        render={val => val || <Text type="danger">未登録</Text>}
      />
      <Table.Column
        title=""
        dataIndex="action"
        key="action"
        align="center"
        render={(_, record) => (
          <Popconfirm
            title={
              <Space direction="vertical">
                <span>このユーザーを削除しますか？</span>
                <Text strong>
                  {record.name}（{record.matchingoodId}）
                </Text>
              </Space>
            }
            okType="danger"
            onConfirm={() => onDelete(record.matchingoodId)}
          >
            <button style={linkButtonStyle} title="削除">
              <CloseCircleOutlined />
            </button>
          </Popconfirm>
        )}
      />
    </Table>
  );
}
