import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from './App';

window.axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  withXSRFToken : true,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
