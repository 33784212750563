import { useState, useEffect } from 'react';
import { getCurrentUser, logout } from '../api';

export default function useCurrentUser() {
  const [user, setUser] = useState(null);
  const [userFetching, setUserFetching] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await getCurrentUser();

        // 管理者以外はログインさせない
        if (response.data.external_app_id) {
          await logout();
          return false;
        }

        setUser(response.data);
      } catch (err) {
      } finally {
        setUserFetching(false);
      }
    })();
  }, []);

  return { user, setUser, userFetching };
}
