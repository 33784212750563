import React, { useState, useEffect } from 'react';
import { Card, Switch, Modal, message, Row, Col } from 'antd';
import { statusPorse, disablePorse, enablePorse } from '../api';

export default function Setup() {
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [searching, setSearching] = useState(false);

  const search = async () => {
    setSearching(true);

    const response = await statusPorse().catch(err => err.response);

    if (response.status === 200) {
      const { status } = response.data;
      setSwitchChecked(status === 'OFF' ? true : false);
    } else {
      message.error('エラーが発生しました。');
    }

    setSearching(false);
  }

  const handleOk = async (value) => {
    const action = value ? enablePorse : disablePorse;
    const successMessage = `求人同期プログラムを${value ? '稼働' : '一時停止'}しました。`;

    const response = await action().catch(e => e.response);
    if (response.status === 200) {
      setSwitchChecked(!value)
      message.success(successMessage);
    } else {
      message.error(successMessage);
    }

    setModalOpen(false)
  }

  const switchStyle = switchChecked
    ? { backgroundColor: '#ff4d4f' }
    : { backgroundColor: '#52c41a' };

  useEffect(() => search(), []);

  return (
    <>
      <Card>
        <Row>
          <Col span={12}>
            <p>求人同期プログラム 稼働状況</p>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren="停止中"
              unCheckedChildren="稼働中"
              onChange={() => setModalOpen(true)}
              checked={switchChecked}
              style={switchStyle}
              loading={searching}
            />
          </Col>
        </Row>
      </Card>
      <Modal
        visible={modalOpen}
        closable={false}
        onOk={() => handleOk(switchChecked)}
        onCancel={() => setModalOpen(false)}
      >
        <p>求人同期プログラム {switchChecked ? '稼働' : '一時停止'}してもよろしいでしょうか？</p>
      </Modal>
    </>
  )
}