import { Form, Input, Button, Alert } from 'antd';
import { useState } from 'react';
import Page from '../components/Page';
import { login, getCurrentUser, logout } from '../api';

export default function Login({ onSuccess }) {
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);

  const failed = () => {
    setSending(false);
    setError('ユーザーID またはパスワードが正しくありません。');
    return false;
  };

  const submit = async values => {
    setSending(true);

    const credentials = { ...values, remember: true };
    const response = await login(credentials).catch(err => err.response);

    if (response.status !== 200) {
      return failed();
    }

    const userResponse = await getCurrentUser().catch(err => err.response);
    if (response.status !== 200) {
      setSending(false);
      return false;
    }

    // 管理者以外はログインさせない
    if (userResponse.data.external_app_id !== null) {
      await logout();
      return failed();
    }

    onSuccess(userResponse.data);
  };

  return (
    <Page>
      <Form
        onFinish={submit}
        colon={false}
        labelAlign="left"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
      >
        {error && (
          <Alert
            message={error}
            type="error"
            showIcon
            style={{ marginBottom: 24 }}
          />
        )}
        <Form.Item
          label="ユーザーID"
          name="username"
          rules={[{ required: true, message: 'ユーザーIDを入力してください' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="パスワード"
          name="password"
          rules={[{ required: true, message: 'パスワードを入力してください' }]}
        >
          <Input.Password />
        </Form.Item>
        <div style={{ marginBottom: 0, marginTop: 32 }}>
          <Button type="primary" block htmlType="submit" loading={sending}>
            ログイン
          </Button>
        </div>
      </Form>
    </Page>
  );
}
